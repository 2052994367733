import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-185504096-1')

export default function GoogleAnalytics() {
  const { pathname } = useLocation()

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [pathname])

  return null
}
