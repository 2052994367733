import React from 'react'
import { Helmet } from 'react-helmet'
import { withCMS } from '../components/app/WithCMS'
import { View, Header } from '../components/ui'
import { CallToAction, TextBlock } from '../components/common'
import StaffBlock from '../components/about/StaffBlock'
import backgroundVideo from '../assets/videos/about.mp4'

import ciaran from '../assets/images/ciaran.png'

import cathal from '../assets/images/cathal.png'

const About = ({ data, sheetNum }) => {
  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="About Irish Commodities, created so that companies can get more from their energy procurement, through proper market analysis and by prioritising market timing."
        />
        <meta
          name="keywords"
          content="energy market analysis, Ciarán Ó Dálaigh, Cathal Ó Dálaigh, fight climate change, head of trading, sustainable carbon, monitoring trading, energy market developments, carbon markets"
        />
      </Helmet>
      <View className="About">
        <Header title={data?.headerText} video={backgroundVideo} />
        <TextBlock
          className="our-company"
          title={data?.title}
          subtitle={data?.subtitle}
          description={data?.description}
        />
        <StaffBlock
          title={data?.staffName1}
          subtitle={data?.staffRole1}
          description={data?.staffBio1}
          background="ciaran-gradient"
          image={ciaran}
          className="ciaran"
          alt="Ciarán Ó Dálaigh"
        />
        <StaffBlock
          title={data?.staffName2}
          subtitle={data?.staffRole2}
          description={data?.staffBio2}
          background="cathal-gradient"
          reverse
          image={cathal}
          className="cathal"
          alt="Cathal Ó Dálaigh"
        />
        <CallToAction text={data?.callToActionText} buttonText={data?.callToActionButton} />
      </View>
    </>
  )
}

export default withCMS(About)
