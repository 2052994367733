import React, { useState, useEffect } from 'react'
import GetSheetDone from 'get-sheet-done'
import { camelCase, get, isUndefined } from 'lodash'
import { LoadingSpinner } from '../ui'

const fetchData = async ({ language, sheetNum }) => {
  const result = await GetSheetDone.labeledColsRows(process.env.REACT_APP_GOOGLE_SHEET_ID, sheetNum)
  const structuredData = await Object.keys(result.data).reduce((obj, key) => {
    const data = result.data
    const formattedKey = camelCase(key)
    const text = get(data, `${key}[${language}]`)
    return (obj = {
      ...obj,
      [formattedKey]: text,
    })
  }, {})

  return await structuredData
}

export const withCMS = (Wrapped) => {
  const WithCMS = ({ ...props }) => {
    const { sheetNum, language, hideLoadingSpinner = false } = props
    const [data, setData] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
      if (!isUndefined(language)) {
        fetchData({ language, sheetNum }).then((res) => {
          setData(res)
          setIsLoading(false)
        })
      }
    }, [language, sheetNum])

    if (isLoading) {
      if (!hideLoadingSpinner) {
        return (
          <div style={{ flex: 1, minHeight: 'calc(100vh - 110px)', top: 0 }} className="column xy-center">
            <LoadingSpinner />
          </div>
        )
      } else {
        return null
      }
    } else {
      return <Wrapped data={data} {...props} />
    }
  }

  return WithCMS
}
