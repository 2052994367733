import React, { useState } from 'react'
import cn from 'classnames'

import { AnimatedItem, AnimateOnReveal } from '../app'
import { Title } from '../common'
import { ChevronIcon } from '../icons'
import { Section } from '../ui'

const getProcessItems = (data, icons, index = '') => {
  let processItems = []
  for (var i = 1; i <= 5; i++) {
    if (data?.[`process${index}ItemTitle${i}`] && data?.[`process${index}ItemTitle${i}`]) {
      processItems.push({
        icon: icons[i - 1],
        title: data?.[`process${index}ItemTitle${i}`],
        description: data?.[`process${index}ItemDescription${i}`],
      })
    }
  }

  return processItems
}

const ProcessItem = ({ icon: Icon, title, description, key, index }) => {
  return (
    <>
      {index !== 0 && (
        <AnimatedItem className="arrow row xy-center">
          <ChevronIcon width={9} />
        </AnimatedItem>
      )}
      <AnimatedItem>
        <div className="ProcessItem column x-center">
          <Icon width={42} />
          <h5 className="title text-primary text-bold text-center mt-20 mb-16">{title}</h5>
          <p className="s text-center">{description}</p>
        </div>
      </AnimatedItem>
    </>
  )
}

const OurProcess = ({ title, subtitle, number, data, icons, icons2 }) => {
  const [tab, setTab] = useState(0)

  const processItems = getProcessItems(data, icons)
  const processItems2 = getProcessItems(data, icons2, 2)

  let processOptions = [
    {
      tab: data?.processOption1,
      title: data?.processOptionDescription1,
      items: processItems,
    },
    {
      tab: data?.processOption2,
      title: data?.processOptionDescription2,
      items: processItems2,
    },
  ]

  const items = processOptions[tab]?.items
  const gridCols = `repeat(${items.length - 1}, minmax(150px, 250px) min-content) minmax(150px, 250px)`

  return (
    <Section className="OurProcess">
      <Title title={title} subtitle={subtitle} number={number} />
      {data?.processDescription && (
        <AnimateOnReveal>
          <AnimatedItem>
            <h4 className="text-default mt-24 mb-32">{data?.processDescription}</h4>
          </AnimatedItem>
        </AnimateOnReveal>
      )}
      {processOptions.length > 1 && (
        <div className="tabs row y-center mt-24 mb-8">
          {processOptions.map((processTab, index) => {
            return (
              <h5 key={index} className={cn('tab', { active: tab === index })} onClick={() => setTab(index)}>
                {processTab?.tab}
              </h5>
            )
          })}
        </div>
      )}
      {processOptions[tab].title && (
        <AnimateOnReveal>
          <AnimatedItem>
            <p className="mt-12">{processOptions[tab].title}</p>
          </AnimatedItem>
        </AnimateOnReveal>
      )}
      <AnimateOnReveal
        className="process-items mt-80"
        style={{ gridTemplateColumns: gridCols }}
        staggerChildren={0.1}
        key={tab}
      >
        {processOptions[tab]?.items?.map((processItem, index) => {
          return processItem && <ProcessItem key={index} index={index} {...processItem} />
        })}
      </AnimateOnReveal>
    </Section>
  )
}

export default OurProcess
