import React from 'react'
import { Helmet } from 'react-helmet'
import { withCMS } from '../components/app/WithCMS'
import { HowCanWeHelp, WhatDoWeOffer } from '../components/home'
import { View, Header } from '../components/ui'
import { CallToAction } from '../components/common'

import backgroundVideo from '../assets/videos/home.mp4'

const Home = ({ data }) => {
  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta
          name="description"
          content="Irish Commodities, obsessively watch wholesale energy markets for the cheapest time to buy your energy. Then we help reduce your business’s energy consumption, and if you want to go carbon neutral (and boost your brand!), we help you do that too."
        />
        <meta
          name="keywords"
          content="irish commodities, commodities, energy, energy procurement, contract selection, demand management, demand response, carbon footprint reduction, energy broker, business energy solutions, reduce your energy, energy consumption"
        />
      </Helmet>
      <View>
        <Header
          className="l"
          // image={backgroundImage}
          video={backgroundVideo}
          title={data?.headerText1}
          // title2={data?.headerText2}
          // title3={data?.headerText3}
        />
        <HowCanWeHelp data={data} />
        <WhatDoWeOffer data={data} />
        <CallToAction text={data?.callToActionText} buttonText={data?.callToActionButton} />
      </View>
    </>
  )
}

export default withCMS(Home)
