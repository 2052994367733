import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { motion, AnimatePresence } from 'framer-motion'
import useSound from 'use-sound'

import { withCMS } from '../components/app/WithCMS'
import { View, Header, Section } from '../components/ui'
import { Title } from '../components/common'
import { AnimatedItem, AnimateOnReveal } from '../components/app'

import { EmailIcon, MapIcon, PhoneIcon, SuccessIcon } from '../components/icons'
import { Field, SubmitButton } from '../components/form'

// import backgroundImage from '../assets/images/contact.jpg'
import backgroundVideo from '../assets/videos/contact.mp4'
import sendSwoosh from '../assets/sounds/send.mp3'

const GOOGLE_FORM_FIRST_NAME = 'entry.1882934352'
const GOOGLE_FORM_LAST_NAME = 'entry.360889947'
const GOOGLE_FORM_EMAIL_ID = 'entry.1604276750'
const GOOGLE_FORM_MESSAGE_ID = 'entry.1663232915'
const GOOGLE_FORM_PHONE_ID = 'entry.124352372'

const GOOGLE_FORM_ACTION_URL =
  'https://docs.google.com/forms/u/1/d/e/1FAIpQLSd6cUQulu5g5jIGlENl6SMLdnLJr_7XZM-X85FcZTkBeAngMA/formResponse'

const CORS_PROXY = 'https://cors-anywhere.herokuapp.com/'

const Contact = ({ data }) => {
  const [success, setSuccess] = useState(false)
  const [play] = useSound(sendSwoosh)

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      message: '',
    },
    validationSchema: Yup.object({
      firstname: Yup.string().max(15, 'Must be 15 characters or less').required('Required'),
      lastname: Yup.string().max(20, 'Must be 20 characters or less').required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      phone: Yup.string().max(14, 'Too long'),
    }),
    onSubmit: (values) => {
      const formData = new FormData()
      formData.append(GOOGLE_FORM_FIRST_NAME, values.firstname)
      formData.append(GOOGLE_FORM_LAST_NAME, values.lastname)
      formData.append(GOOGLE_FORM_EMAIL_ID, values.email)
      formData.append(GOOGLE_FORM_PHONE_ID, values?.phone)
      formData.append(GOOGLE_FORM_MESSAGE_ID, values?.message)
      axios
        .post(CORS_PROXY + GOOGLE_FORM_ACTION_URL, formData)
        .then(() => {
          play()
          formik.setSubmitting(false)
          formik.resetForm()
          setSuccess(true)
          setTimeout(() => {
            setSuccess(false)
          }, 3000)
        })
        .catch(() => {
          formik.setSubmitting(false)
          formik.setErrors({ general: data?.errorMessage })
        })
    },
  })

  return (
    <>
      <Helmet>
        <title>Talk To Us</title>
        <meta
          name="description"
          content="Talk to us at Irish Commodities, let us show you how we can drive value to your business. We promise your email won’t disappear into the inbox abyss. Or if you are in the mood for an old-fashioned telephone call, we are here to answer your questions."
        />
        <meta
          name="keywords"
          content="drive value, irish commodities, contact irish commodities, talk to irish commodities, message irish commodities, ennis irish commodities"
        />
      </Helmet>
      <View className="Contact">
        <Header title={data?.headerText} video={backgroundVideo} />
        <Section>
          <div className="opening">
            <Title className="mb-16" title={data?.title} subtitle={data?.subtitle} />
            <AnimateOnReveal>
              <p className="l">{data?.description}</p>
            </AnimateOnReveal>
          </div>
          <AnimateOnReveal className="details-and-form row mt-64 mb-80">
            <div className="contact-details column">
              <AnimateOnReveal>
                <AnimatedItem className="contact-detail">
                  <a className="link l row y-top" href={`mailto:${data?.email}`}>
                    <EmailIcon />
                    <h4>{data?.email}</h4>
                  </a>
                </AnimatedItem>
                <AnimatedItem className="contact-detail">
                  <a className="link l row y-top" href={`tel:${data?.phone}`}>
                    <PhoneIcon className="phone" />
                    <h4>{data?.phone}</h4>
                  </a>
                </AnimatedItem>
                <AnimatedItem className="contact-detail">
                  <div className="link l row y-top">
                    <MapIcon />
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: data?.address?.replace(/\n/g, '<br/>'),
                      }}
                    />
                  </div>
                </AnimatedItem>
              </AnimateOnReveal>
            </div>
            <div className="contact-form">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <Field name="firstname" label="First Name" placeholder="John" formik={formik} required />
                  <Field name="lastname" label="Last Name" placeholder="Smith" formik={formik} required />
                </div>
                <div className="row">
                  <Field
                    name="email"
                    label="Email Address"
                    placeholder="john.smith@example.com"
                    type="email"
                    formik={formik}
                    required
                  />
                  <Field name="phone" label="Phone Number" placeholder="+353 871234567" type="number" formik={formik} />
                </div>
                <Field
                  name="message"
                  label="Message"
                  placeholder="Type your message here"
                  type="textarea"
                  component="textarea"
                  formik={formik}
                  cols="30"
                  rows="4"
                />
                <div className="button-wrapper mt-32 row x-right y-center">
                  {formik.errors.general && <div className="error-message mr-24">{formik.errors.general}</div>}
                  <SubmitButton className="button l" formik={formik} />
                </div>
              </form>
              <AnimatePresence>
                {success && (
                  <motion.div
                    className="success-message column xy-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <SuccessIcon />
                    <h2 className="text-primary">{data?.sendSuccessTitle}</h2>
                    <p className="l mt-12">{data?.sendSuccessSubtitle}</p>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </AnimateOnReveal>
        </Section>
      </View>
    </>
  )
}

export default withCMS(Contact)
