import React, { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useViewportScroll, motion } from 'framer-motion'

import LogoAndTextIcon from '../icons/LogoAndTextIcon'
import { withCMS } from './WithCMS'
import { NavMenuMobile } from '.'

const SERVICE_MENU = (data) => [
  {
    label: data?.service1,
    to: '/service/energy-procurement',
  },
  {
    label: data?.service2,
    to: '/service/demand-management',
  },
  {
    label: data?.service3,
    to: '/service/demand-response',
  },
  {
    label: data?.service4,
    to: '/service/carbon-footprint',
  },
]

const navAnimate = {
  initial: {
    height: 120,
    boxShadow: 'none',
    transition: {
      duration: 0.4,
    },
  },
  shrink: {
    height: 100,
    boxShadow: '0 3px 5px rgba(57, 63, 72, 0.3)',
    transition: {
      duration: 0.4,
    },
  },
}

const languageLinkAnimate = {
  initial: {
    paddingBottom: 6,
    transition: {
      duration: 0.4,
    },
  },
  shrink: {
    paddingBottom: 0,
    transition: {
      duration: 0.4,
    },
  },
}

const subMenuAnimate = {
  enter: {
    display: 'flex',
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.25,
      staggerChildren: 0.05,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    y: -5,
    transitionEnd: {
      display: 'none',
    },
    transition: {
      duration: 0.25,
    },
  },
}

const item = {
  exit: { opacity: 0, x: -20, transition: { delay: 0.5 } },
  enter: {
    opacity: 1,
    x: 0,
  },
}

const Navbar = ({ language, changeLanguage, data }) => {
  const [isTop, setIsTop] = useState(true)
  const [isMouse, setMouse] = useState(false)
  const { scrollY } = useViewportScroll()

  useEffect(() => {
    const trackScroll = () => {
      if (scrollY.current < 50) {
        setIsTop(true)
      } else if (scrollY.current >= 50) {
        setIsTop(false)
      } else {
        return null
      }
    }

    window.addEventListener('scroll', trackScroll)
    return () => window.removeEventListener('scroll', trackScroll)
  }, [scrollY])

  return (
    <motion.nav className="Navbar" variants={navAnimate} initial="initial" animate={isTop ? 'initial' : 'shrink'}>
      <div className="content navbar-content">
        <Link className="logo" to="/">
          <LogoAndTextIcon />
        </Link>
        <motion.div
          className="language-link nav-language-link"
          variants={languageLinkAnimate}
          initial="initial"
          animate={isTop ? 'initial' : 'shrink'}
        >
          <div className="link l" onClick={() => changeLanguage(language === 'english' ? 'irish' : 'english')}>
            {language === 'english' ? 'Gaeilge' : 'English'}
          </div>
        </motion.div>
        <div className="links-wrapper">
          <div className="links">
            <NavLink to="/" className="link">
              {data?.home}
            </NavLink>
            <motion.div
              className="link sub-menu-link row x-center"
              onClick={() => setMouse(!isMouse)}
              onHoverStart={() => setMouse(true)}
              onHoverEnd={() => setMouse(false)}
            >
              {data?.services}
              <motion.div
                className="sub-menu column x-left"
                initial="exit"
                animate={isMouse ? 'enter' : 'exit'}
                variants={subMenuAnimate}
              >
                {SERVICE_MENU(data).map(({ label, to }, index) => (
                  <motion.div className="sublink" key={index} variants={item}>
                    <Link to={to} className="link text-nowrap text-center" onClick={() => setMouse(false)}>
                      {label}
                    </Link>
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>
            <NavLink to="/about" className="link">
              {data?.about}
            </NavLink>
            <NavLink className="button l" to="/contact">
              {data?.contact}
            </NavLink>
          </div>
        </div>
        <NavMenuMobile
          servicesMenu={SERVICE_MENU(data)}
          data={data}
          language={language}
          changeLanguage={changeLanguage}
        />
      </div>
    </motion.nav>
  )
}

export default withCMS(Navbar)
