import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Footer, GoogleAnalytics, Navbar, ScrollToTop } from './components/app'
import { About, Contact, Home, Service } from './views'

import {
  energyIcons,
  energyIcons2,
  energyKeywords,
  energyDescription,
  demandManagementIcons,
  demandManagementKeywords,
  demandManagementDescription,
  demandResponseIcons,
  demandResponseKeywords,
  demandResponseDescription,
  carbonFootprintIcons,
  carbonFootprintKeywords,
  carboonFootprintDescription,
} from './utils/constants'

import energryVideo from './assets/videos/energy.mp4'
import demandManagementVideo from './assets/videos/demand-management.mp4'
import demandResponseVideo from './assets/videos/demand-response.mp4'
import carbonFootprintVideo from './assets/videos/carbon-footprint.mp4'

import './styles/index.css'

function App() {
  const [language, setLanguage] = useState()

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language')
    if (savedLanguage) {
      setLanguage(savedLanguage)
    } else {
      setLanguage('english')
    }
  }, [])

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage)
    localStorage.setItem('language', newLanguage)
  }

  return (
    <>
      <Router>
        <GoogleAnalytics />
        <ScrollToTop />
        <Navbar language={language} changeLanguage={changeLanguage} sheetNum={8} hideLoadingSpinner />
        <Route exact path="/" render={(props) => <Home {...props} sheetNum={1} language={language} />} />
        <Route
          exact
          path="/service/energy-procurement"
          render={(props) => (
            <Service
              {...props}
              sheetNum={2}
              language={language}
              video={energryVideo}
              backgroundPosition="bottom"
              icons={energyIcons}
              icons2={energyIcons2}
              keywords={energyKeywords}
              description={energyDescription}
            />
          )}
        />
        <Route
          exact
          path="/service/demand-management"
          render={(props) => (
            <Service
              {...props}
              sheetNum={3}
              language={language}
              video={demandManagementVideo}
              icons={demandManagementIcons}
              keywords={demandManagementKeywords}
              description={demandManagementDescription}
            />
          )}
        />
        <Route
          exact
          path="/service/demand-response"
          render={(props) => (
            <Service
              {...props}
              sheetNum={4}
              language={language}
              video={demandResponseVideo}
              // backgroundPosition="bottom"
              icons={demandResponseIcons}
              keywords={demandResponseKeywords}
              description={demandResponseDescription}
            />
          )}
        />
        <Route
          exact
          path="/service/carbon-footprint"
          render={(props) => (
            <Service
              {...props}
              sheetNum={5}
              language={language}
              video={carbonFootprintVideo}
              icons={carbonFootprintIcons}
              keywords={carbonFootprintKeywords}
              description={carboonFootprintDescription}
            />
          )}
        />
        <Route exact path="/about" render={(props) => <About {...props} sheetNum={6} language={language} />} />
        <Route exact path="/contact" render={(props) => <Contact {...props} sheetNum={7} language={language} />} />
        <Footer sheetNum={7} language={language} hideLoadingSpinner />
      </Router>
    </>
  )
}

export default App
