import React from 'react'
import cn from 'classnames'
import { useViewportScroll, motion, useTransform } from 'framer-motion'

const Header = ({ className, title, title2, title3, image, video, backgroundPosition = 'center' }) => {
  const { scrollY } = useViewportScroll()
  const y = useTransform(scrollY, [0, 500], [20, -80])

  return (
    <header className={cn('Header', className)}>
      {video && (
        <video className="video" autoPlay muted loop id="myVideo" style={{ objectPosition: backgroundPosition }}>
          <source src={video} />
        </video>
      )}
      <div className="content column y-bottom">
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, ease: 'easeOut' }}
          style={{ y: y }}
        >
          <h1 className="text-bold">{title}</h1>
        </motion.div>
      </div>
    </header>
  )
}

export default Header
