import React from 'react'
import cn from 'classnames'

const Section = ({ className, contentClassName, children }) => {
  return (
    <section className={cn('Section', className)}>
      <div className={cn('content', contentClassName)}>{children}</div>
    </section>
  )
}

export default Section
