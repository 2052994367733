import React from 'react'
import cn from 'classnames'

const Submitting = ({ labelSubmitting }) => (
  <>
    {labelSubmitting}
    <span className="LoadingDots">
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  </>
)

const SubmitButton = ({
  className,
  label = 'Send',
  labelSubmitting = 'Sending',
  formik: { isSubmitting, handleSubmit, isValid, dirty, initialErrors, values },
}) => {
  return (
    <button
      type="submit"
      // className={classNames('button', { inactive: !dirty })}
      className={cn('SubmitButton button', className)}
      onClick={handleSubmit}
      disabled={isSubmitting}
    >
      {isSubmitting ? <Submitting labelSubmitting={labelSubmitting} /> : label}
    </button>
  )
}

export default SubmitButton
